@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    font-family: 'Maven Pro' !important;
}

div#root {
  height: 100vh;
  overflow: hidden;
}

.dashboard {
    height: 100vh;
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
}

aside {
    grid-column: 1/2;
    grid-row: 1/2;
    height: 100vh;
    background: rgba(2,35,57,1);
    width: 100%;
    overflow: hidden;
}

.dashboard.compact {
    grid-template-columns: 68px 1fr;
}

.dashboard.none {
  grid-template-columns: 0 1fr;
}

main {
  grid-column: 2/3;
  grid-row: 1/2;
  background: #F5F9FF;

  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

.jobCard {
    display: grid;
    grid-template-columns: 270px 1fr 350px;
}


:root {
    --tooltip-text-color: white;
    --tooltip-background-color: black;
    --tooltip-margin: 10px;
    --tooltip-arrow-size: 6px;
  }
  
  .Tooltip-Wrapper {
    display: flex;
    position: relative;
  }
  
  .Tooltip-Tip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;
  }
  
  .Tooltip-Tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }
  
  .Tooltip-Tip.top {
    top: calc(var(--tooltip-margin) * -1);
  }

  .Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: var(--tooltip-background-color);
  }
  
  .Tooltip-Tip.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .Tooltip-Tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-background-color);
  }
  
  .Tooltip-Tip.bottom {
    bottom: calc(var(--tooltip-margin) * -1);
  }

  .Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--tooltip-background-color);
  }
  
  .Tooltip-Tip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .Tooltip-Tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-background-color);
  }
  
  .choose::-webkit-file-upload-button {
    color: white;
    display: inline-block;
    background: wheat;
    border: none;
    width: 200px;
    padding: 5px 10px;
    font-weight: 700;
    border-radius: 3px;
    white-space: nowrap;
    cursor: pointer;
    font-size: 10pt;
  }

  input[type=file] {
    color: transparent !important;
  }

  .clip-3-lines {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
              line-clamp: 3; 
      -webkit-box-orient: vertical;
  }

  .jFanTw, .kAGTos {
    min-width: 175px !important;
  }

  .fhekZh, .ftOJmt {
    min-width: 175px !important;
  }

  /* Global Scroller styles */
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
  }

    /* Makes the animation pause on hover */
  .moving-text:hover{
      animation-play-state: paused;
  }

  /* The animation */
  @keyframes marquee{
      0%{transform: translateX(100%);}
      100%{transform: translateX(-100%);}
  }

  /* media query to enable animation for only those who want it */
  @media (prefers-reduced-motion: no-preference) {
      .moving-text{
          animation: marquee 15s linear infinite;
      }
  }

  .image-transition-enter {
    opacity: 0;
  }
  
  .image-transition-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
  }
  
  .image-transition-exit {
    opacity: 1;
  }
  
  .image-transition-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  
  .top-left {
    top: 0;
    left: 0;
  }
  
  .top-right {
    top: 0;
    right: 0;
  }
  
  .bottom-left {
    bottom: 100px;
    left: 0;
  }
  
  .bottom-right {
    bottom: 100px;
    right: 0;
  }
  
  .corner-transition-enter {
    opacity: 0;
  }
  
  .corner-transition-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
  }