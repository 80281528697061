@import '~antd/dist/antd.less';

p {
    margin-bottom: 0;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
}

@media (max-width: 768px) {
    .ant-space-item{
        width: 100%;
    }
}
@primary-color: #FDB82C;